import { Spin } from 'antd';
import React, { lazy, Suspense, useEffect } from 'react';
import { Route, Routes, useLocation, Navigate } from 'react-router-dom';
import withAdminLayout from '../../layout/withAdminLayout';


// Profile
const Profile = lazy(() => import('../../RentEZ/Profile/EditProfile'))
const ChangePassword = lazy(() => import('../../RentEZ/Profile/ChangePassword'))

// Categories
const MainCategories = lazy(() => import('../../RentEZ/Categories/MainCategory/MainCategory'));
const ParentSubCategories = lazy(() => import('../../RentEZ/Categories/ParentSubCategories/ParentSubCategories'));
const ChildSubCategories = lazy(() => import('../../RentEZ/Categories/ChildSubCategories/ChildSubCategories'));


//Listings
const AllListing = lazy(() => import('../../RentEZ/Listing/All/AllListing'));
const AllListingDetails = lazy(() => import('../../RentEZ/Listing/All/AllListingDetails'));
const PendingListing = lazy(() => import('../../RentEZ/Listing/Pending/PendingListing'));
const PendingListingDetails = lazy(() => import('../../RentEZ/Listing/Pending/PendingListingDetails'));
const Booking = lazy(()=>import ('../../RentEZ/Listing/Booking/Booking'))
const BookingDetails = lazy(()=>import ('../../RentEZ/Listing/Booking/BookingDetails'))

// Payouts
const PendingPayouts = lazy(() => import('../../RentEZ/Payouts/PendingPayouts/PendingPayouts'));
const DeclinedPayouts = lazy(() => import('../../RentEZ/Payouts/DeclinedPayouts/DeclinedPayouts'));
const ApprovedPayouts = lazy(() => import('../../RentEZ/Payouts/ApprovedPayouts/ApprovedPayouts'));

//Payments
const Payments = lazy(() => import('../../RentEZ/Payments/Payment'));
const PaymentDetails = lazy(() => import('../../RentEZ/Payments/PaymentDetails'));
// Users
const AllUsers = lazy(() => import('../../RentEZ/Users/AllUsers/AllUsers'));
const AllUserDetails = lazy(() => import('../../RentEZ/Users/AllUsers/AllUserDetails'));
const PendingRegisterations = lazy(() => import('../../RentEZ/Users/PendingRegisterations/PendingRegisterations'));


//CMS
const BannerManagement = lazy(() => import('../../RentEZ/CMS/BannerManagement/BannerManagement'))
const CreateBanner = lazy(() => import('../../RentEZ/CMS/BannerManagement/CreateBanner'))

const TermsConditions = lazy(() => import('../../RentEZ/CMS/TermsConditions/TermsConditions'))
const CreateTermsConditions = lazy(() => import('../../RentEZ/CMS/TermsConditions/CreateTermsConditions'))
const PrivacyPolicy = lazy(() => import('../../RentEZ/CMS/PrivacyPolicy/PrivacyPolicy'))
const FAQs = lazy(() => import('../../RentEZ/CMS/FAQs/FAQs'))
const RefundPolicy = lazy(() => import('../../RentEZ/CMS/RefundPolicy/RefundPolicy'))
const ContactUs = lazy(() => import('../../RentEZ/Settings/ContactUs'))

// Notifications
const Notifications = lazy(() => import('../../RentEZ/Notifications/Notifications'));

// Settings
const ContactUS = lazy(() => import('../../RentEZ/Settings/ContactUs'));
const PostRate = lazy(() => import('../../RentEZ/Settings/PostRate'));

// Not Found
const NotFound = lazy(() => import('../../container/pages/404'));

// Coming Soon
const ComingSoon = lazy(() => import('../../container/pages/ComingSoon'));


const Admin = React.memo(() => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <Suspense
      fallback={
        <div className="spin">
          <Spin />
        </div>
      }
    >
      <Routes>

      {/*Dashboard */}
      <Route path="admin/dashboard" element={<ComingSoon />} />
      <Route path="admin/editprofile" element={<Profile />}  />
      <Route path="admin/password/edit" element={<ChangePassword />}  />

       {/* Categories */}
       <Route path="admin/categories/maincategories" element={<MainCategories />} />
       <Route path="admin/categories/parentsubcategories" element={<ParentSubCategories />} />
       <Route path="admin/categories/childsubcategories" element={<ChildSubCategories />} />
       
      
      {/*Product Listing*/}
       <Route path="admin/listing/allproducts" element={<AllListing />} />
       <Route path="admin/listing/allproductdetails" element={<AllListingDetails />} />
       <Route path="admin/listing/pendingproducts" element={<PendingListing />} />
       <Route path="admin/listing/pendinglistingdetails" element={<PendingListingDetails />} />

       {/* Booking Listing */}
       <Route path="admin/listing/allbookings" element={<Booking />} />
       <Route path="admin/listing/bookingdetails" element={<BookingDetails />} />

        {/* Payments */}
       <Route path="admin/payments" element={<Payments />} />
       <Route path="admin/payments/payment-details" element={<PaymentDetails />} />

        {/* Payouts */}
        <Route path="admin/payout/pending-payouts" element={<PendingPayouts />} />
        <Route path="admin/payout/declined-payouts" element={<DeclinedPayouts />} />
        <Route path="admin/payout/approved-payouts" element={<ApprovedPayouts />} />
      
        {/* Users */}
        <Route path="admin/users/allusers" element={<AllUsers />} />
        <Route path="admin/users/allusers-details" element={<AllUserDetails />} />
        <Route path="admin/users/pending-registerations" element={<PendingRegisterations />} />

        {/* CMS */}
        <Route path="admin/cms/contactus" element={<ContactUs />} />

        <Route path="admin/cms/bannermanagement" element={<BannerManagement />} />
        <Route path="admin/cms/bannerManagement/create" element={<CreateBanner />} />
        <Route path="admin/cms/terms&Conditions" element={<TermsConditions />} />
        <Route path="admin/cms/terms&Conditions/create" element={<CreateTermsConditions />}  />
        <Route path="admin/cms/privacypolicy/create" element={<CreateTermsConditions />}  />
        <Route path="admin/cms/faqs/create" element={<CreateTermsConditions />}  />
        <Route path="admin/cms/refundpolicy/create" element={<CreateTermsConditions />}  />

        <Route path="admin/cms/bannerManagement/edit" element={<CreateBanner />} />
        <Route path="admin/cms/terms&Conditions/edit" element={<CreateTermsConditions />}  />
        <Route path="admin/cms/privacypolicy/edit" element={<CreateTermsConditions />}  />
        <Route path="admin/cms/faqs/edit" element={<CreateTermsConditions />}  />
        <Route path="admin/cms/refundpolicy/edit" element={<CreateTermsConditions />}  />

        <Route path="admin/cms/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="admin/cms/refundpolicy" element={<RefundPolicy />} />
        <Route path="admin/cms/faqs" element={<FAQs />} />
        
        {/* Notifications */}
        <Route path="admin/notifications" element={<Notifications />} />
        
        {/* Settings */}
        <Route path="admin/settings/contactus" element={<ContactUS />} />
        <Route path="admin/settings/post-rate" element={<PostRate />} />
      
       {/* Not Found */}
       {/* <Route path="*" element={<NotFound />} /> */}
       <Route path="*" element={<Navigate to="/admin/dashboard" />} />
      </Routes>
    </Suspense>
  );
});

export default withAdminLayout(Admin);
