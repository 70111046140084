import {
  UilCreateDashboard,
  UilFileShieldAlt,
  UilSetting,
  UilShoppingCart,
  UilUsersAlt,
  UilBell,
  UilMoneyWithdraw,
  UilListUiAlt,
  UilEllipsisV,
  UilCreditCard 
} from '@iconscout/react-unicons';
import { Menu } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import propTypes from 'prop-types';
import { NavTitle } from './Style';
import versions from '../demoData/changelog.json';
import { changeDirectionMode, changeLayoutMode, changeMenuMode } from '../redux/themeLayout/actionCreator';
function MenuItems({ toggleCollapsed }) {
  const { t } = useTranslation();

  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  const { topMenu } = useSelector((state) => {
    return {
      topMenu: state.ChangeLayoutMode.topMenu,
    };
  });

  const dispatch = useDispatch();

  const path = '/admin';

  const pathName = window.location.pathname;
  const pathArray = pathName ? pathName.split(path) : [];
  const mainPath = pathArray.length > 1 ? pathArray[1] : '';
  const mainPathSplit = mainPath.split('/');

  const [openKeys, setOpenKeys] = React.useState(
    !topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : [],
  );

  const onOpenChange = (keys) => {
    setOpenKeys(keys[keys.length - 1] !== 'recharts' ? [keys.length && keys[keys.length - 1]] : keys);
  };

  const onClick = (item) => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };

  // const changeLayout = (mode) => {
  //   dispatch(changeLayoutMode(mode));
  // };

  // const changeNavbar = (topMode) => {
  //   const html = document.querySelector('html');
  //   if (topMode) {
  //     html.classList.add('ninjadash-topmenu');
  //   } else {
  //     html.classList.remove('ninjadash-topmenu');
  //   }
  //   dispatch(changeMenuMode(topMode));
  // };

  // const changeLayoutDirection = (rtlMode) => {
  //   if (rtlMode) {
  //     const html = document.querySelector('html');
  //     html.setAttribute('dir', 'rtl');
  //   } else {
  //     const html = document.querySelector('html');
  //     html.setAttribute('dir', 'ltr');
  //   }
  //   dispatch(changeDirectionMode(rtlMode));
  // };

  // const darkmodeActivated = () => {
  //   document.body.classList.add('dark-mode');
  // };

  // const darkmodeDiactivated = () => {
  //   document.body.classList.remove('dark-mode');
  // };

  const items = [
    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/dashboard`}>
        {t('dashboard')}
      </NavLink>,
      'dashboard',
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/dashboard`}>
          <UilCreateDashboard />
        </NavLink>
      ),
    ),

    getItem(t('Categories'), 'categories', !topMenu && <UilShoppingCart />, [
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/categories/maincategories`}>
          {t('Main Categories')}
        </NavLink>,
        'maincategories',
        null,
      ),
      // getItem(
      //   <NavLink onClick={toggleCollapsed} to={`${path}/categories/addcategories`}>
      //     {t('Add Categories')}
      //   </NavLink>,
      //   'addcategories',
      //   null,
      // ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/categories/parentsubcategories`}>
          {t('Parent Sub Categories')}
        </NavLink>,
        'parentsubcategories',
        null,
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/categories/childsubcategories`}>
          {t('Child Sub Categories')}
        </NavLink>,
        'childsubcategories',
        null,
      ),
      // getItem(
      //   <NavLink onClick={toggleCollapsed} to={`${path}/crud/categories`}>
      //     {t('Manage Listings')}
      //   </NavLink>,
      //   '/Manage Listings',
      //   null,
      // ),
      // getItem(
      //   <NavLink onClick={toggleCollapsed} to={`${path}/crud/categories`}>
      //     {t('All Listings')}
      //   </NavLink>,
      //   '/All Listings',
      //   null,
      // ),
    ]),

    getItem(t('Product'), 'listing', !topMenu && <UilListUiAlt />, [
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/listing/pendingproducts`}>
          {t('Pending Products')}
        </NavLink>,
        'pendingproducts',
        null,
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/listing/allproducts`}>
          {t('All Products')}
        </NavLink>,
        'allproducts',
        null,
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/listing/allbookings`}>
          {t('All Bookings')}
        </NavLink>,
        'allbookings',
        null,
      ),
    ]),
    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/payments`}>
        {t('Payments')}
      </NavLink>,
      'payments',
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/payments`}>
          <UilCreditCard  />
        </NavLink>
      ),
    ),

    getItem(t('Payouts'), 'payout', !topMenu && <UilMoneyWithdraw />, [
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/payout/pending-payouts`}>
          {t('Pending Payouts')}
        </NavLink>,
        'pending-payouts',
        null,
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/payout/approved-payouts`}>
          {t('Approved Payouts')}
        </NavLink>,
        'approved-payouts',
        null,
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/payout/declined-payouts`}>
          {t('Declined Payouts')}
        </NavLink>,
        'declined-payouts',
        null,
      ),
    ]),

    getItem(t('Users'), 'users', !topMenu && <UilUsersAlt />, [
      // getItem(
      //   <NavLink onClick={toggleCollapsed} to={`${path}/users/pending-registerations`}>
      //     {t('Pending Registrations')}
      //   </NavLink>,
      //   'pending-registerations',
      //   null,
      // ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/users/allusers`}>
          {t('All Users')}
        </NavLink>,
        'allusers',
        null,
      ),
    ]),

    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/notifications`}>
        {t('Notification')}
      </NavLink>,
      'notifications',
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/notifications`}>
          <UilBell />
        </NavLink>
      ),
    ),

    getItem(t('CMS'), 'cms', !topMenu && <UilFileShieldAlt />, [
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/cms/bannermanagement`}>
          {t('Banner Management')}
        </NavLink>,
        'bannermanagement',
        null,
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/cms/terms&Conditions`}>
          {t('Terms and Conditions')}
        </NavLink>,
        'terms&Conditions',
        null,
      ),
      // getItem(
      //   <NavLink onClick={toggleCollapsed} to={`${path}/cms/privacyPolicy`}>
      //     {t('Privacy Poli')}
      //   </NavLink>,
      //   'privacy policy',
      //   null,
      // ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/cms/privacypolicy`}>
          {t('Privacy Policy')}
        </NavLink>,
        'privacypolicy',
        null,
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/cms/faqs`}>
          {t('FAQs')}
        </NavLink>,
        'faqs',
        null,
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/cms/refundpolicy`}>
          {t('Refund Policy')}
        </NavLink>,
        'refundpolicy',
        null,
      ),
    ]),
    getItem(t('Settings'), 'settings', !topMenu && <UilSetting />, [
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/settings/contactus`}>
          {t('Site Settings')}
        </NavLink>,
        'contactus',
        null,
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/settings/post-rate`}>
          {t('Post Rate')}
        </NavLink>,
        'post-rate',
        null,
      ),
    ]),
  ];

  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
      // // eslint-disable-next-line no-nested-ternary
      defaultSelectedKeys={
        !topMenu
          ? [
              `${
                mainPathSplit.length === 1 ? 'home' : mainPathSplit.length === 2 ? mainPathSplit[1] : mainPathSplit[2]
              }`,
            ]
          : []
      }
      defaultOpenKeys={!topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : []}
      overflowedIndicator={<UilEllipsisV />}
      openKeys={openKeys}
      items={items}
    />
  );
}

MenuItems.propTypes = {
  toggleCollapsed: propTypes.func,
};

export default MenuItems;
